<template>
  <div class="pageBox">
    <div class="pageBoxTop">
      <img class="resultBg" src="../assets/resultBg.png" />
      <div class="pageBoxTopdiv">
        <div class="pageBoxTopName">{{ projectName }}</div>
        <div class="pageBoxTopData">输出结果汇总</div>
      </div>
    </div>

    <div class="pageBoxContent">
      <div class="sectionContent">
        <img src="../assets/systemBg.png" class="systemBg" />
        <div class="systemVal1">
          <div class="systemValTop">{{ pvData.dc }}</div>
          <div class="systemValBottom">kW</div>
        </div>
        <div class="systemVal2">
          <div class="systemValTop">{{ contentGroup[0].volume }}</div>
          <div class="systemValBottom">
            {{ contentGroup[0].volumeWanShow ? "万" : "" }}kW
          </div>
        </div>
        <div class="systemVal3">
          <div class="systemValTop">{{ contentGroup[1].value }}</div>
          <div class="systemValBottom">kW</div>
          <div class="systemValTop mt10">{{ contentGroup[1].total }}</div>
          <div class="systemValBottom">kWh</div>
        </div>
      </div>

      <div
        class="contentItem"
        v-for="(item, index) in contentGroup"
        :key="index"
      >
        <div class="contentItemTop">
          <img class="contentItemTopIcon" :src="item.src" />
          <div class="contentItemTopdiv">{{ item.name }}</div>
        </div>
        <div class="contentItemCenter" v-if="index == 0">
          <div>
            <span class="contentItemCenterValue">{{ item.volume }}</span>
            <span class="contentItemCenterLabel">
              {{ contentGroup[0].volumeWanShow ? "万" : "" }}kW
            </span>
          </div>
          <div class="mt5">
            <span class="contentItemCenterValue">{{ item.value }}</span>
            <span class="contentItemCenterLabel">
              {{ contentGroup[0].valueWanShow ? "万" : "" }}kWh/年
            </span>
          </div>
        </div>
        <div class="contentItemCenter" v-if="index == 1">
          <div>
            <span class="contentItemCenterValue">{{ item.value }}</span>
            <span class="contentItemCenterLabel">kW</span>
          </div>
          <div class="mt5">
            <span class="contentItemCenterValue">{{ item.total }}</span>
            <span class="contentItemCenterLabel">kWh</span>
          </div>
        </div>
        <div class="contentItemCenter" v-if="index == 2">
          <span class="contentItemCenterValue">{{ item.value }}</span>
          <span class="contentItemCenterLabel">kW</span>
        </div>
        <div class="contentItemCenter" v-if="index == 3">
          <div>
            <span class="contentItemCenterValue">+{{ item.valueu }}</span>
            <span class="contentItemCenterLabel">kW</span>
          </div>
          <div>
            <span class="contentItemCenterValue">{{ item.value }}</span>
            <span class="contentItemCenterLabel">kW</span>
          </div>
        </div>
        <div class="contentItemCenter" v-if="index == 4">
          <span class="contentItemCenterValue" style="font-size: 25px">
            {{ item.value }}
            <span class="contentItemCenterLabel">万元</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "Main",
  props: {
    contentGroup: Array,
  },
  data() {
    return {
      token: "",
      projectId: "",
      projectName: "dfdfd",
      pvData: {
        dc: 1,
      },
      xx: {
        volume: 0,
        volumeWanShow: 0,
        value: 0,
        total: 0,
      },
    };
  },
  mounted() {
    this.getToken();
    this.getProject();
    this.getDc();
  },
  methods: {
    getToken() {
      var url = window.location.href; //获取当前url

      var dz_url = url.split("#")[0]; //获取#/之前的字符串

      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串

      var cs_arr = cs.split("&"); //参数字符串分割为数组

      var csz = {};

      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象

        csz[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.token = csz.token;
      this.projectId = csz.projectId;
      console.log(this.token);
    },
    getProject: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/project/"+_this.projectId, {
          params: {
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          console.log('项目信息');
          console.log(res);
          _this.projectName=res.data.data.projectName
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getDc: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/result/dc", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          res.data.data.pv = Number(res.data.data.pv).toFixed(0);
          res.data.data.storage = Number(res.data.data.storage).toFixed(0);
          res.data.data.dc = Number(res.data.data.dc).toFixed(0);
          _this.pvData = res.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pageBox {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  box-sizing: border-box;
}
.pageBoxTop {
  position: relative;
}
.resultBg {
  width: 100%;
  height: 200px;
}
.pageBoxTopdiv {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 34px 27px;
}
.pageBoxTopName {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #ffffff;
}
.pageBoxTopData {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 8px;
}
.pageBoxContent {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -90px;
  position: relative;
  z-index: 1;
}
.contentItem {
  width: calc(50% - 8px);
  height: 130px;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 16px 12px;
  margin-bottom: 16px;
  position: relative;
}
.contentItemTop {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.contentItemTopIcon {
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 8px;
}
.contentItemCenter {
  margin: 16px 0 0px;
  line-height: 24px;
  box-sizing: border-box;
}
.contentItemCenterValue {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
}
.contentItemCenterLabel {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-left: 2.5px;
}
.contentItemBottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2f80ed;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.contentItemBottom image {
  width: 15px;
  height: 15px;
  display: block;
  margin-left: 5px;
}
.popupBox {
  width: 100%;
  min-height: 240px;
  box-sizing: border-box;
  padding: 16px 16px 86px;
}
.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.inputGroupLeft {
  width: 80px;
  height: 100%;
}
.inputGroup input {
  width: calc(100% - 80rpx);
  height: 100%;
  div-align: left;
}

.sectionContent {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
}
.sectionContentBg {
  position: absolute;
  bottom: -15px;
  right: -10px;
  width: 222px;
  height: 111px;
}
.systemBg {
  width: 100%;
  height: 320px;
}
.systemVal1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 33%;
  left: 25%;
}

.systemVal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38%;
  left: 65%;
}

.systemVal3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 56%;
  left: 59%;
}
.systemValTop {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}
.systemValBottom {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.showTips {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 3;
}
</style>
