<template>
  <div id="app">
    <div
      style="
        width: 100%;
        padding: 20px;
        background: #f8f8f8;
        text-align: center;
      "
    >
      <van-button type="primary" @click="exportPDF">点击导出PDF报告</van-button>
    </div>
    <div id="pdfHtml" ref="printHtml">
      <div style="padding: 10px 15px 0 15px">
        <h1 style="text-align: center">
         <img src="./assets/loginIcon.png" style="width:35px;vertical-align:text-top;margin-top:2px" /> <span class="tc">&nbsp;{{ project.projectName }}&nbsp;</span>&nbsp;&nbsp;光储直柔计算报告
        </h1>
        <div style="text-align: right">报告生成日期：{{formartDate()}}</div>
      </div>
      <div style="padding: 15px; padding-bottom: 0;padding-top:10px">
        <table class="gridtable" style="width: 100%">
          <tr>
            <th rowspan="4">项目基本信息</th>
            <th>项目名称</th>
            <td>{{ project.projectName }}</td>
            <th>项目地址</th>
            <td>{{ project.projectLocation + project.projectAddress }}</td>
            <th>项目类型</th>
            <td>{{ pt[project.projectType] }}</td>
            <th>建筑功能</th>
            <td>{{ pu[project.projectUse] }}</td>
            <th>建筑面积</th>
            <td>{{ project.projectBuildingArea }}㎡</td>
          </tr>
          <tr>
            <th>设计目标</th>
            <td colspan="3">
              {{
                project.pedfProjectTarget.targetTarget == "1"
                  ? "光伏自用率最大化"
                  : "柔性用电"
              }}
            </td>
            <th>直流配电范围</th>
            <td colspan="5">
              {{
                project.pedfProjectTarget.targetEle == "1"
                  ? "全直流配电"
                  : "空调照明直流配电"
              }}
            </td>
          </tr>
          <tr>
            <th>光伏安装位置</th>
            <td>
              {{ project.pedfProjectPv.pvPostion == "1" ? "屋顶" : "立面" }}
            </td>
            <th>光伏安装面积</th>
            <td>{{ project.pedfProjectPv.pvArea }}㎡</td>
            <th>光伏组件</th>
            <td colspan="5">
              {{ project.pedfProjectPv.pvComponent == "1" ? "晶硅" : "非晶硅" }}
            </td>
          </tr>
          <tr>
            <th>单位面积用电量</th>
            <td colspan="3">
              {{ project.pedfProjectPowerNew.powerAmount }}kWh/㎡
            </td>
            <th>充电桩是否能有序充放电</th>
            <td colspan="5">
              {{
                project.pedfProjectPowerNew.powerChargeType == "N" ? "否" : "是"
              }}
            </td>
          </tr>
        </table>
      </div>
      <div style="padding: 15px; padding-top: 0">
        <table class="gridtable" style="width: 100%">
          <tr>
            <td colspan="4" class="tdd">计算结果*</td>
          </tr>
          <tr>
            <td style="width: 425px">
              <Main
                msg=""
                :contentGroup="contentGroup"
                :token="token"
                :projectId="projectId"
              />
            </td>
            <td style="width: 425px">
              <Pv
                msg=""
                :contentGroup="contentGroup"
                :token="token"
                :projectId="projectId"
              />
            </td>
            <td rowspan="2" style="width: 425px">
              <Energy
                msg=""
                :contentGroup="contentGroup"
                style="margin-top: -5px"
              />
            </td>
            <td rowspan="2" style="width: 425px">
              <Soft
                msg=""
                :contentGroup="contentGroup"
                :token="token"
                :projectId="projectId"
                style="margin-top: 10px"
              />
            </td>
          </tr>
          <tr>
            <td>
              <Direct
                msg=""
                :contentGroup="contentGroup"
                :token="token"
                :projectId="projectId"
              />
            </td>
            <td>
              <Money
                msg=""
                :contentGroup="contentGroup"
                :token="token"
                :projectId="projectId"
                style="margin-top:-30px"
              />
            </td>
          </tr>
          <tr>
            <th colspan="4" style="font-size: 18px">
              *注：该报告计算结果基于“光储直柔（PEDF）设计计算工具”小程序，模型经适当简化，计算结果仅供参考。<br />如有任何意见或建议，欢迎反馈至中国建筑节能协会光储直柔专业委员会，联系方式：PEDF_cabee@126.com
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import Main from "./components/Main.vue";
import Pv from "./components/Pv.vue";
import Energy from "./components/Energy.vue";
import Direct from "./components/Direct.vue";
import Soft from "./components/Soft.vue";
import Money from "./components/Money.vue";

import { getPdf } from "./utils/exportPdf";

export default {
  name: "App",
  components: {
    Main,
    Pv,
    Energy,
    Direct,
    Soft,
    Money,
  },
  data() {
    return {
      token: "",
      projectId: "",
      contentGroup: [
        {
          src: require("./assets/resultIcon1.png"),
          name: "光伏系统",
          value: "",
          valueWanShow: false,
          volumeWanShow: false,
          url: "../../subPages/pages/analysics1/index",
          // url:'../analysics1/index'
        },
        {
          src: require("./assets/resultIcon2.png"),
          name: "储能系统",
          value: "",
          url: "../../subPages/pages/analysics2/index",
          // url:'../analysics2/index'
        },
        {
          src: require("./assets/resultIcon3.png"),
          name: "直流配电",
          value: "",
          url: "../../subPages/pages/analysics3/index",
          // url:'../analysics3/index'
        },
        {
          src: require("./assets/resultIcon4.png"),
          name: "柔性用电",
          value: "",
          url: "../../subPages/pages/analysics4/index",
          // url:'../analysics4/index'
        },
        {
          src: require("./assets/resultIcon5.png"),
          name: "系统投资概算",
          value1: "",
          value2: "",
          url: "../../subPages/pages/analysics5/index",
          // url:'../analysics5/index'
        },
      ],
      project: {},
      pt: ["", "新建", "改造"],
      pu: ["", "商业", "办公", "教育", "展览展示", "综合体", "住宅", "工业"],
    };
  },
  mounted() {
    this.getToken();
    this.getProject();
    this.getGeneralInfo();
  },
  methods: {
    getToken() {
      var url = window.location.href; //获取当前url

      var dz_url = url.split("#")[0]; //获取#/之前的字符串

      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串

      var cs_arr = cs.split("&"); //参数字符串分割为数组

      var csz = {};

      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象

        csz[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.token = csz.token;
      this.projectId = csz.projectId;
      console.log(this.token);
    },

    getProject: function () {
      let _this = this;
      let token = this.token;
      axios
        .get(
          "https://pedf.ibr-x.com:8080/api/wechat/project/" + _this.projectId,
          {
            params: {
              id: _this.projectId,
            },
            headers: {
              Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
            },
          }
        )
        .then(function (res) {
          let project = res.data.data;
          console.log("xxxxxx");
          console.log(project);
          _this.project = project;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getGeneralInfo: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/result/general", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          let generalInfo = res.data.data;

          if (generalInfo.mainPvVolume > 10000) {
            _this.contentGroup[0].volumeWanShow = true;
          } else {
            _this.contentGroup[0].volumeWanShow = false;
          }
          if (generalInfo.mainPvAmount > 10000) {
            _this.contentGroup[0].valueWanShow = true;
          } else {
            _this.contentGroup[0].valueWanShow = false;
          }

          _this.contentGroup[0].volume = Number(
            generalInfo.mainPvVolume > 10000
              ? generalInfo.mainPvVolume / 10000
              : generalInfo.mainPvVolume
          ).toFixed(0);
          _this.contentGroup[0].value = Number(
            generalInfo.mainPvAmount > 10000
              ? generalInfo.mainPvAmount / 10000
              : generalInfo.mainPvAmount
          ).toFixed(0);
          _this.contentGroup[1].total = Number(
            generalInfo.mainStorageAmount
          ).toFixed(0);
          _this.contentGroup[1].value = Number(
            generalInfo.mainStoragePower
          ).toFixed(0);
          _this.contentGroup[2].value = Number(
            generalInfo.mainDcAmount
          ).toFixed(0);
          _this.contentGroup[3].valueu = Number(
            generalInfo.mainPowerChange
          ).toFixed(0);

          _this.contentGroup[3].value = Number(
            generalInfo.mainFlexAmunt
          ).toFixed(0);
          _this.contentGroup[4].value =
            Number(generalInfo.mainInvestL).toFixed(0) +
            "~" +
            Number(generalInfo.mainInvestH).toFixed(0);
          // _this.contentGroup[4].value1 = Number(generalInfo.mainInvestL).toFixed(0);
          // _this.contentGroup[4].value2 = Number(generalInfo.mainInvestH).toFixed(0);
          _this.mainPowerChange = Number(generalInfo.mainPowerChange).toFixed(
            0
          );
          //  / _this.contentGroup=_this.contentGroup;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formartDate() {
      var dates = new Date();
      // 2020-2-2 21:30:21
      // 月
      var month = dates.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      // 日
      var date = dates.getDate();
      date = date < 10 ? "0" + date : date;
      // 时
      // var hours = dates.getHours();
      // hours = hours < 10 ? "0" + hours : hours;
      // // 分
      // var minutes = dates.getMinutes();
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // // 秒
      // var seconds = dates.getSeconds();
      // seconds = seconds < 10 ? "0" + seconds : seconds;
      var arr = [
        //年
        dates.getFullYear() + "-",
        month + "-",
        date + " ",
      ];
      return arr.join("");
    },
    exportPDF() {
      getPdf("项目报告", "pdfHtml");
    },
  },
};
</script>

<style>
.mt15 {
  margin-top: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.topTitle {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin-top: -50px;
  margin-bottom: 22px;
}

table.gridtable {
  font-family: verdana, arial, sans-serif;
  font-size: 20px;
  color: #333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
}
table.gridtable th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  color:#fff;
  border-color: #666666;
  background-color: rgba(122, 174, 241, 0.88);
}
table.gridtable td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #666666;
  background-color: #ffffff;
  font-weight: bold;
}
.tc{
  border-bottom: solid 3px #000;
  margin-left:10px ;
}
.tdd{
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
</style>
