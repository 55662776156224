<template>
  <div class="pageBox">
    <div class="pageBoxTopBg"></div>
    <div class="contentBox">
      <div class="topTitle">直流配电</div>
      <div class="sectionContent">
        <img class="sectionContentBg" src="../assets/sectionContentBg.png" />
        <div class="mb15">
          基于
          <span class="black bolden">{{ aimArray[aimIndex].name }}</span>
          的设计目标，
        </div>
        <div class="topSectionContent">
          <div class="topSectionContentRight">
            <div class="topSectionContentTitle">
              <image
                class="topSectionContentLeft"
                src="../assets/resultIcon3.png"
              />
              <span class="ml5">该项目所需交直变换器功率约为：</span>
            </div>
            <div class="topSectionContentVal">
              {{ contentGroup[2].value }}<span> kW</span>
            </div>
            <div class="mt10" style="color: #000">
              比常规设计所需的配电变压器容量
              <span style="font-weight: bold; font-size: 22px">{{ x }}</span> kW
            </div>
            <div class="mt10" style="color: #000">
              下降了约
              <span style="font-weight: bold; font-size: 22px">{{ rate }}</span
              >%
            </div>
          </div>
        </div>
        <div class="tips">说明：因输入数据有限，结果仅供参考。</div>
      </div>
      <div class="sectionTitle mt15">系统容量示意图</div>
      <div class="sectionContent">
        <img src="../assets/systemBg.png" class="systemBg" />
        <div class="systemVal1">
          <div class="systemValTop">{{ pvData.dc }}</div>
          <div class="systemValBottom">kW</div>
        </div>
        <div class="systemVal2">
          <div class="systemValTop">{{ contentGroup[0].volume }}</div>
          <div class="systemValBottom">
            {{ contentGroup[0].volumeWanShow ? "万" : "" }}kW
          </div>
        </div>
        <div class="systemVal3">
          <div class="systemValTop">{{ contentGroup[1].value }}</div>
          <div class="systemValBottom">kW</div>
          <!-- <div class="systemValTop mt10">{{ contentGroup[1].total }}</div>
          <div class="systemValBottom">kWh</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "Direct",
  props: {
    contentGroup: Array,
  },
  watch: {
    token(val) {
      //message即为父组件的值，val参数为值
      this.token = val; //将父组件的值赋给childrenMessage 子组件的值
    },
    projectId(val) {
      //message即为父组件的值，val参数为值
      this.projectId = val; //将父组件的值赋给childrenMessage 子组件的值
    },
  },
  data() {
    return {
      token: "",
      projectId: "",
      aimIndex: 0,
      aimArray: [
        {
          name: "光伏自用率最大化",
          value: 1,
        },
        {
          name: "柔性用电",
          value: 2,
        },
      ],
      pvData: "",
      x: "",
      rate: "",
      volumeWanShow: false,
    };
  },
  mounted() {
    this.getToken();
    this.getTarget();
    this.getDc();
  },
  methods: {
    getToken() {
      var url = window.location.href; //获取当前url

      var dz_url = url.split("#")[0]; //获取#/之前的字符串

      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串

      var cs_arr = cs.split("&"); //参数字符串分割为数组

      var csz = {};

      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象

        csz[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.token = csz.token;
      this.projectId = csz.projectId;
      console.log(this.token);
    },
    getTarget: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/target/", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          for (let i = 0; i < _this.aimArray.length; i++) {
            if (_this.aimArray[i].value == res.data.data.targetTarget) {
              _this.aimIndex = i;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getDc: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/result/dc", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          console.log(res);
          res.data.data.pv = Number(res.data.data.pv).toFixed(0);
          res.data.data.storage = Number(res.data.data.storage).toFixed(0);
          res.data.data.dc = Number(res.data.data.dc).toFixed(0);
          res.data.data.x = Number(res.data.data.x).toFixed(0);
          res.data.data.rate = Number(res.data.data.rate).toFixed(0);

          _this.pvData = res.data.data;
          _this.x = res.data.data.x;
          _this.rate = res.data.data.rate;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pageBox {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
  padding-bottom: 385px;
  box-sizing: border-box;
}

.pageBoxTopBg {
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, #2f80ed 0%, #f6f6f6 100%);
}

.contentBox {
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding: 16px;
  margin-top: -105px;
}

.sectionTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 10px;
}
.sectionContent {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.sectionContentBg {
  position: absolute;
  bottom: -15px;
  right: -10px;
  width: 222px;
  height: 111px;
}
.systemBg {
  width: 100%;
  height: 320px;
}

.topSectionContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.topSectionContentLeft {
  width: 24px;
  height: 24px;
}

.topSectionContentRight {
  width: calc(100% - 30px);
}

.topSectionContentTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.topSectionContentVal {
  width: 100%;
  margin-top: 5px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
}

.topSectionContentVal text {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
}

.systemValTop {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}
.systemValBottom {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.systemVal1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 33%;
  left: 25%;
}

.systemVal2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38%;
  left: 65%;
}

.systemVal3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 56%;
  left: 59%;
}
.tips {
  font-size: 12px;
  color: #000;
  margin-top: 10px;
}
</style>
