<template>
  <div class="pageBox">
    <div class="pageBoxTopBg"></div>
    <div class="contentBox">
      <div class="topTitle">光伏系统</div>

      <div class="sectionContent">
        <img class="sectionContentBg" src="../assets/sectionContentBg.png" />
        <div class="mb15">
          基于
          <span class="black bolden">{{ aimArray[aimIndex].name }}</span>
          的设计目标，
        </div>
        <div class="topSectionContent">
          <div class="topSectionContentRight">
            <div class="topSectionContentTitle">
              <img
                class="topSectionContentLeft"
                src="../assets/resultIcon1.png"
              />光伏系统装机容量约为
            </div>
            <div class="topSectionContentVal">
              {{ contentGroup[0].volume }}
              <span>{{ contentGroup[0].volumeWanShow ? "万" : "" }}kWp</span>
            </div>
            <span class="topSectionContentTitle mt10">年发电量约为：</span>
            <div class="topSectionContentVal">
              {{ contentGroup[0].volume }}
              <span>{{ contentGroup[0].valueWanShow ? "万" : "" }}kWh</span>
            </div>
            <div class="tips">说明：因输入数据有限，结果仅供参考。</div>
          </div>
        </div>
      </div>
      <div class="sectionTitle mt15">光伏系统逐月发电量与建筑用电量示意图</div>
      <div class="sectionContent">
        <div class="echart-container" v-if="showChart1">
          <div
            class="mychart"
            id="mychart"
            style="width: 100%; height: 350px"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "Pv",
  props: {
    msg: String,
    contentGroup: Array,
  },
  data() {
    return {
      token: "",
      projectId: "",
      target: "",
      showChart1: true,
      volume: "",
      volumeWanShow: true,
      valueWanShow: true,
      total: 0,
      chartData: [],
      chartData1: [],
      aimArray: [
        {
          name: "光伏自用率最大化",
          value: 1,
        },
        {
          name: "柔性用电",
          value: 2,
        },
      ],
      aimIndex: 0,
    };
  },
  mounted() {
    this.getToken();
    this.charts();
    this.getTarget();
    console.log("dddfd");

    console.log(this.contentGroup);
  },
  methods: {
    getToken() {
      var url = window.location.href; //获取当前url

      var dz_url = url.split("#")[0]; //获取#/之前的字符串

      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串

      var cs_arr = cs.split("&"); //参数字符串分割为数组

      var csz = {};

      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象

        csz[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.token = csz.token;
      this.projectId = csz.projectId;
      console.log(this.token);
    },
    getTarget: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/target/", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          for (let i = 0; i < _this.aimArray.length; i++) {
            if (_this.aimArray[i].value == res.data.data.targetTarget) {
              _this.aimIndex = i;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    charts: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/result/pvMonth", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          let chartData = res.data.data;
          let chartData1 = [];
          let chartData2 = [];
          for (let i = 0; i < 2; i++) {
            if (chartData[i].type == "pv") {
              chartData1.push(chartData[i].jan);
              chartData1.push(chartData[i].feb);
              chartData1.push(chartData[i].mar);
              chartData1.push(chartData[i].apr);
              chartData1.push(chartData[i].may);
              chartData1.push(chartData[i].jun);
              chartData1.push(chartData[i].jul);
              chartData1.push(chartData[i].aug);
              chartData1.push(chartData[i].sept);
              chartData1.push(chartData[i].oct);
              chartData1.push(chartData[i].nov);
              chartData1.push(chartData[i].dece);
            } else if (chartData[i].type == "power") {
              chartData2.push(chartData[i].jan);
              chartData2.push(chartData[i].feb);
              chartData2.push(chartData[i].mar);
              chartData2.push(chartData[i].apr);
              chartData2.push(chartData[i].may);
              chartData2.push(chartData[i].jun);
              chartData2.push(chartData[i].jul);
              chartData2.push(chartData[i].aug);
              chartData2.push(chartData[i].sept);
              chartData2.push(chartData[i].oct);
              chartData2.push(chartData[i].nov);
              chartData2.push(chartData[i].dece);
            }
          }

          _this.chartData = chartData1;
          _this.chartData1 = chartData2;
          _this.showChart1 = true;
          console.log(chartData1);
          console.log(chartData2);
          // 基于准备好的dom，初始化echarts实例

          var myChart = _this.$echarts.init(document.getElementById("mychart"));

          // 指定图表的配置项和数据

          var option = {
            color: ["#91cc75", "#5470c6"],

            legend: {
              top: 0,
              right: 0,
              itemWidth: 10,
              itemHeight: 10,
            },
            tooltip: {
              trigger: "item",
              formatter: "{a}{b}{c}kWh",
            },
            grid: {
              top: "15%",
              left: "1%",
              right: "1%",
              bottom: "3%",
              containLabel: true,
            },
            yAxis: {
              name: "kWh",
              type: "value",
              boundaryGap: [0, 0.01],
            },
            xAxis: {
              type: "category",
              data: [
                "1月",
                "2月",
                "3月",
                "4月",
                "5月",
                "6月",
                "7月",
                "8月",
                "9月",
                "10月",
                "11月",
                "12月",
              ],
              axisLabel: {
                textStyle: {
                  fontSize: 9,
                },
              },
              axisTick: {
                show: false,
              },
            },
            series: [
              {
                barWidth: 10,
                name: "光伏发电量",
                type: "bar",
                data: chartData1,
              },
              {
                barWidth: 10,
                name: "建筑用电量",
                type: "bar",
                data: chartData2,
              },
            ],
          };

          // 使用刚指定的配置项和数据显示图表。

          myChart.setOption(option);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pageBox {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
  padding-bottom: 144px;
  box-sizing: border-box;
}

.pageBoxTopBg {
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, #2f80ed 0%, #f6f6f6 100%);
}

.contentBox {
  margin-top: -105px;
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding: 16px;
}

.sectionTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 10px;
}

.sectionContent {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.sectionContentBg {
  position: absolute;
  bottom: -15px;
  right: -10px;
  width: 222px;
  height: 111px;
}

.chartTitle {
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
  text-align: center;
}

.echart-container {
  width: 100%;
  height: 350px;
}

.selectBox {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #555555;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.selectItem {
  width: 60px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  border-radius: 12px;
  margin-right: 5px;
}

.selectItemActive {
  background: rgba(47, 128, 237, 0.1);
}

.selectItem:nth-last-child(1) {
  margin-right: 0;
}

.topSectionContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.topSectionContentLeft {
  width: 24px;
  height: 24px;
}

.topSectionContentRight {
  width: calc(100% - 30px);
}

.topSectionContentTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 10px;
  margin-bottom: 10px; */
}

.topSectionContentVal {
  width: 100%;
  margin-top: 5px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
}

.topSectionContentVal text {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
}

.datePicker {
  position: absolute;
  right: 0;
  top: 0;
}
.tips {
  font-size: 12px;
  color: #000;
  margin-top: 10px;
}
</style>
