<template>
  <div class="pageBox">
    <div class="pageBoxTopBg"></div>
    <div class="contentBox">
      <div class="topTitle">投资概算</div>

      <div class="sectionContent">
        <img class="sectionContentBg" src="../assets/sectionContentBg.png" />
        <div class="mb15">
          基于
          <span class="black bolden">{{ aimArray[aimIndex].name }}</span>
          的设计目标，
        </div>
        <div class="topSectionContent">
          <div class="topSectionContentRight">
            <div class="topSectionContentTitle">
              <img
                class="topSectionContentLeft"
                src="../assets/resultIcon5.png"
              />
              <span class="ml5">该项目光储直柔系统投资约为：</span>
            </div>
            <div class="topSectionContentVal">
              {{ contentGroup[4].value }}<span> 万元</span>
            </div>
          </div>
        </div>
        <div class="tips">说明：因输入数据有限，结果仅供参考。</div>
      </div>
      <div class="sectionTitle mt15">系统投资示意图</div>
      <div class="sectionContent">
        <div class="echart-container">
          <div
            class="mychart"
            id="mychartm"
            style="width: 100%; height: 350px"
          ></div>
        </div>
        <div class="lg">
          <div class="tc"><van-icon name="info-o" />说明</div>
          <div>若有安装充电桩，则投资包含在储能中</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "Money",
  props: {
    contentGroup: Array,
  },
  data() {
    return {
      token: "",
      projectId: "",
      aimArray: [
        {
          name: "光伏自用率最大化",
          value: 1,
        },
        {
          name: "柔性用电",
          value: 2,
        },
      ],
      aimIndex: 0,

      chartData: [],
    };
  },
  mounted() {
    this.getToken();
    this.getInvest();
    this.getTarget();
  },
  methods: {
    getToken() {
      var url = window.location.href; //获取当前url

      var dz_url = url.split("#")[0]; //获取#/之前的字符串

      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串

      var cs_arr = cs.split("&"); //参数字符串分割为数组

      var csz = {};

      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象

        csz[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.token = csz.token;
      this.projectId = csz.projectId;
      console.log(this.token);
    },
    getTarget: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/target/", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          for (let i = 0; i < _this.aimArray.length; i++) {
            if (_this.aimArray[i].value == res.data.data.targetTarget) {
              _this.aimIndex = i;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getInvest: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/result/invest", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          _this.chartData = res.data.data;
          var myChart = _this.$echarts.init(
            document.getElementById("mychartm")
          );

          // 指定图表的配置项和数据

          var option = {
            color: [
              "#70AD47",
              "#FFC000",
              "#ED7D31",
              "#2F80ED",
              "#FF0000",
              "#934ADD",
            ],
            tooltip: {
              trigger: "item",
              formatter: (params) => {
                console.log(params);
                return (
                  params.name +
                  "\n" +
                  params.data.vl.toFixed(0) +
                  "~" +
                  params.data.vh.toFixed(0) +
                  "万元" +
                  "\n" +
                  params.percent +
                  "%"
                );
              },
              position: [10, 10],
            },
            legend: {
              bottom: 0,
              itemWidth: 10,
              itemHeight: 10,
            },
            series: [
              {
                center: ["50%", "45%"],
                type: "pie",
                radius: ["0%", "65%"],
                label: {
                  formatter: (params) => {
                    console.log(params);
                    return (
                      params.name +
                      "\n" +
                      params.data.vl.toFixed(0) +
                      "~" +
                      params.data.vh.toFixed(0) +
                      "万元" +
                      "\n" +
                      params.percent +
                      "%"
                    );
                  },
                  position: "outside",
                  fontSize: 11,
                  color: "#000",
                },
                labelLine: {
                  show: true,
                  length: 5,
                  length2: 5,
                },

                data: [
                  {
                    value: _this.chartData.investPv,
                    vh: _this.chartData.investPvH,
                    vl: _this.chartData.investPvL,
                    name: "光伏系统",
                    itemStyle: { color: "#EB5757" },
                  },
                  {
                    value:
                      _this.chartData.investBattery +
                      _this.chartData.investCharge,
                    vh:
                      _this.chartData.investBatteryH +
                      _this.chartData.investChargeH,
                    vl:
                      _this.chartData.investBatteryL +
                      _this.chartData.investChargeL,
                    name: "储能",
                    itemStyle: { color: "#FFB258" },
                  },

                  {
                    value: _this.chartData.investBox,
                    vh: _this.chartData.investBoxH,
                    vl: _this.chartData.investBoxL,
                    name: "直流配电",
                    itemStyle: { color: "#27AE60" },
                  },
                  {
                    value: _this.chartData.investLine,
                    vh: _this.chartData.investLineH,
                    vl: _this.chartData.investLineL,
                    name: "开关及线缆",
                    itemStyle: { color: "#549dff" },
                  },
                ],
              },
            ],
          };
          // 使用刚指定的配置项和数据显示图表。

          myChart.setOption(option);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pageBox {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
  padding-bottom: 382px;
  box-sizing: border-box;
}

.pageBoxTopBg {
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, #2f80ed 0%, #f6f6f6 100%);
}

.contentBox {
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding: 16px;
  margin-top: -105px;
}

.sectionTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 10px;
}
.sectionContent {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.sectionContentBg {
  position: absolute;
  bottom: -15px;
  right: -10px;
  width: 222px;
  height: 111px;
}
.chartTitle {
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
  text-align: center;
}
.echart-container {
  width: 100%;
  height: 350px;
}
.selectBox {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #555555;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.selectItem {
  width: 60px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  border-radius: 12px;
  margin-right: 5px;
}
.selectItemActive {
  background: rgba(47, 128, 237, 0.1);
}
.selectItem:nth-last-child(1) {
  margin-right: 0;
}

.topSectionContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.topSectionContentLeft {
  width: 24px;
  height: 24px;
}

.topSectionContentRight {
  width: calc(100% - 30px);
}

.topSectionContentTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.topSectionContentVal {
  width: 100%;
  margin-top: 5px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
}

.topSectionContentVal text {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
}
.tips {
  font-size: 12px;
  color: #000;
  margin-top: 10px;
}
.lg {
  font-size: 13px;
  line-height: 20px;
  margin-top: 25px;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
}
.lg .title {
  font-weight: bold;
}
.lg .tc {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
</style>
