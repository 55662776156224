<template>
  <div class="pageBox">
    <div class="pageBoxTopBg"></div>
    <div class="contentBox">
      <div class="topTitle">柔性用电</div>

      <div class="sectionContent">
        <img class="sectionContentBg" src="../assets/sectionContentBg.png" />
        <div class="mb15">
          基于
          <span class="black bolden">{{ aimArray[aimIndex].name }}</span>
          的设计目标，
        </div>
        <div class="topSectionContent">
          <div class="topSectionContentRight">
            <div class="topSectionContentTitle">
              <img
                class="topSectionContentLeft"
                src="../assets/resultIcon4.png"
              />
              <span class="ml5">该项目柔性用电可调节负荷为：</span>
            </div>
            <div class="topSectionContentTitle mt10">向上调节最大功率：</div>
            <div class="topSectionContentVal">
              {{ contentGroup[3].valueu }}<span>kW</span>
            </div>
            <div class="topSectionContentTitle mt10">向下调节最大功率：</div>
            <div class="topSectionContentVal">
              {{ contentGroup[3].value }}<span>kW</span>
            </div>
          </div>
        </div>
        <div class="tips">说明：因输入数据有限，结果仅供参考。</div>
      </div>
      <div class="sectionTitle mt15">最不利天储能调节示意图</div>
      <div class="sectionContent">
        <div class="selectBox">
          <span>季节：</span>
          <div
            data-label="select"
            :class="['selectItem', index == 0 ? 'selectItemActive' : '']"
            v-for="(item, index) in selectItemGroup"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="echart-container mt0" v-if="showChart2">
          <div
            class="mychart"
            id="mychart11"
            style="width: 100%; height: 350px"
          ></div>
        </div>
        <div class="selectBox">
          <span>季节：</span>
          <div
            data-label="select"
            :class="['selectItem', index == 1 ? 'selectItemActive' : '']"
            v-for="(item, index) in selectItemGroup"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="echart-container mt0" v-if="showChart2">
          <div
            class="mychart"
            id="mychart22"
            style="width: 100%; height: 350px"
          ></div>
        </div>
        <div class="selectBox">
          <span>季节：</span>
          <div
            data-label="select"
            :class="['selectItem', index == 2 ? 'selectItemActive' : '']"
            v-for="(item, index) in selectItemGroup"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="echart-container mt0" v-if="showChart2">
          <div
            class="mychart"
            id="mychart33"
            style="width: 100%; height: 350px"
          ></div>
        </div>
        <div class="selectBox">
          <span>季节：</span>
          <div
            data-label="select"
            :class="['selectItem', index == 3 ? 'selectItemActive' : '']"
            v-for="(item, index) in selectItemGroup"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="echart-container mt0" v-if="showChart2">
          <div
            class="mychart"
            id="mychart44"
            style="width: 100%; height: 350px"
          ></div>
        </div>
        <div class="lg">
          <div class="tc"><van-icon name="info-o" />图例说明</div>
          <div>
            <span class="title" style="color: #375ea6">车辆充放：</span
            >电动汽车有序充电或者放电（如有双向充电桩），在满足用车需求的前提下利用冗余的电池容量
          </div>
          <div>
            <span class="title" style="color: #9fe187">电池储能：</span
            >投资建设分布式储能电池系统实现可再生电能的储存
          </div>
          <div>
            <span class="title" style="color: #f3c879">负荷调节：</span
            >利用建筑中的可调节设备实现负荷的削减和迁移
          </div>
          <div>
            <span class="title" style="color: #e23773">光伏弃电：</span
            >在电力供给极端富余的情况下放弃部分光伏发电量，减少储能容量需求，提高储能全年利用率
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "Soft",
  props: {
    msg: String,

    contentGroup: Array,
  },
  data() {
    return {
      token: "",
      projectId: "",
      showChart2: true,
      aimArray: [
        {
          name: "光伏自用率最大化",
          value: 1,
        },
        {
          name: "柔性用电",
          value: 2,
        },
      ],
      aimIndex: 0,
      selectActiveIndex: 0,
      selectItemGroup: [
        {
          name: "春",
          id: 1,
        },
        {
          name: "夏",
          id: 2,
        },
        {
          name: "秋",
          id: 3,
        },
        {
          name: "冬",
          id: 4,
        },
      ],
      pvData: [],
      storageinData: [],
      storageoutData: [],
      originData: [],
      airData: [],
      targetData: [],
    };
  },
  mounted() {
    this.getToken();
    this.charts("春");
    this.charts("夏");
    this.charts("秋");
    this.charts("冬");
    this.getTarget();
  },
  methods: {
    getToken() {
      var url = window.location.href; //获取当前url

      var dz_url = url.split("#")[0]; //获取#/之前的字符串

      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串

      var cs_arr = cs.split("&"); //参数字符串分割为数组

      var csz = {};

      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象

        csz[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.token = csz.token;
      this.projectId = csz.projectId;
      console.log(this.token);
    },
    getTarget: function () {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/target/", {
          params: {
            projectId: _this.projectId,
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          for (let i = 0; i < _this.aimArray.length; i++) {
            if (_this.aimArray[i].value == res.data.data.targetTarget) {
              _this.aimIndex = i;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    charts: function (season) {
      let _this = this;
      let token = this.token;
      axios
        .get("https://pedf.ibr-x.com/api/wechat/result/storage", {
          params: {
            projectId: _this.projectId,
            season: season,
            dayType: "工作日",
          },
          headers: {
            Authorization: token, //这里是关键,设置好Authorization,将定义的token放在后面即可
          },
        })
        .then(function (res) {
          console.log(res);
          let chartData = res.data.data;
          _this.pvData = chartData.map((item) => item.itemPv);
          _this.storageinData = chartData.map((item) => item.itemStoragein);
          _this.storageoutData = chartData.map((item) => item.itemStorageout);
          _this.airData = chartData.map((item) => item.itemAir);
          _this.originData = chartData.map((item) => item.itemOrigin);
          _this.targetData = chartData.map((item) => item.itemTarget);
          _this.appendData = chartData.map((item) => item.itemAppend1);
          _this.showChart2 = true;

          var myChart = null;
          if (season == "春") {
            myChart = _this.$echarts.init(document.getElementById("mychart11"));
          } else if (season == "夏") {
            myChart = _this.$echarts.init(document.getElementById("mychart22"));
          } else if (season == "秋") {
            myChart = _this.$echarts.init(document.getElementById("mychart33"));
          } else if (season == "冬") {
            myChart = _this.$echarts.init(document.getElementById("mychart44"));
          }

          // 指定图表的配置项和数据

          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
              },
              position: [10, 10],
            },
            legend: {
              bottom: 0,
              itemWidth: 10,
              itemHeight: 10,
            },
            grid: {
              top: "10%",
              left: "3%",
              right: "4%",
              bottom: "17%",
              containLabel: true,
            },
            yAxis: {
              type: "value",
            },
            xAxis: {
              type: "category",
              data: [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
              ],
            },
            dataZoom: [
              {
                type: "inside",
                start: 0,
                end: 100,
              },
            ],
            series: [
              {
                name: "原建筑用电负荷",
                type: "line",
                emphasis: {
                  focus: "series",
                },
                data: _this.originData,
              },
              {
                name: "原建筑并网点负荷",
                type: "line",
                emphasis: {
                  focus: "series",
                },
                data: _this.targetData,
              },
              {
                name: "目标并网点负荷",
                type: "line",
                emphasis: {
                  focus: "series",
                },
                data: _this.appendData,
              },
            ],
          };

          myChart.setOption(option);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pageBox {
  width: 100%;
  min-height: 100vh;
  background-color: #f6f6f6;
  padding-bottom: 131rpx;
  box-sizing: border-box;
}

.pageBoxTopBg {
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, #2f80ed 0%, #f6f6f6 100%);
}

.contentBox {
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding: 16px;
  margin-top: -105px;
}

.sectionTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 10px;
}

.sectionContent {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.sectionContentBg {
  position: absolute;
  bottom: -15px;
  right: -10px;
  width: 222px;
  height: 111px;
}

.chartTitle {
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
  span-align: center;
}

.echart-container {
  width: 100%;
  height: 350px;
}

.selectBox {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #555555;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.selectItem {
  width: 60px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  border-radius: 12px;
  margin-right: 5px;
}

.selectItemActive {
  background: rgba(47, 128, 237, 0.1);
}

.selectItem:nth-last-child(1) {
  margin-right: 0;
}

.topSectionContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.topSectionContentLeft {
  width: 24px;
  height: 24px;
}

.topSectionContentRight {
  width: calc(100% - 30px);
}

.topSectionContentTitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.topSectionContentVal {
  width: 100%;
  margin-top: 5px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #000000;
}

.topSectionContentVal span {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.58);
}

.tips {
  font-size: 12px;
  color: #000;
  margin-top: 10px;
}

.lg {
  font-size: 13px;
  line-height: 20px;
  margin-top: 25px;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
}

.lg .title {
  font-weight: bold;
}

.lg .tc {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
</style>
